<template>
  <div class="customer-detail-content">
    <!-- 导航栏 -->
    <NavBar :title="typeText"> </NavBar>

    <p class="py-8 text-right transportBillNumber text-12 text-muted" v-if="chatDataRef.transportBillNumber">
      关联运单号：{{ chatDataRef.transportBillNumber }}
    </p>
    <div v-if="chatDataRef.messages && chatDataRef.messages.length" class="content">
      <div v-for="item in chatDataRef.messages" :key="item.id">
        <div v-if="item.messageType === 1">
          <p class="date">{{ formatToDateTime(item.createTime, 'yyyy-MM-dd hh:mm') }}</p>
          <div class="question">
            <Icon class="head-img" svgClass="icon-middle" name="mrtx-b"></Icon>
            <div style="float:right;" class="relative text-right">
              <p class="text-left text" style="float: none;">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="date">{{ formatToDateTime(item.createTime, 'yyyy-MM-dd hh:mm') }}</p>
          <div class="answer">
            <Icon class="head-img" svgClass="icon-middle" name="mrtx-l"></Icon>
            <p class="text">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from 'vant';
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { useRoute } from '@/utils/compsitionHack';
import useGetDifferenceInfo from './hooks/useGetDifferenceInfo';
import { formatToDateTime } from '@wlhy-web-lib/shared';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar,
    Button
  },
  setup(_, ctx) {
    const { query } = useRoute();

    const chatDataRef = ref({});

    // 提交
    const handleSubmit = () => {
      Toast('提交成功！');
      // 如果是在客户端环境，调用客户端返回方法
      ctx.root.$routerBack.call(ctx.root._router);
    };

    // query.type - 0: 装货; 1: 卸货
    const { typeText, apiName } = useGetDifferenceInfo(+query.type);

    onBeforeMount(async () => {
      // 获取列表数据
      try {
        const res = await apiName({ id: +query.id });
        if (res) {
          let data = res.data;
          chatDataRef.value = data;
        }
      } catch (err) {
        Toast.fail(err);
      }
    });

    return {
      typeText,
      handleSubmit,
      chatDataRef,
      formatToDateTime
    };
  }
});
</script>
<style lang="less" scoped>
.customer-detail-content {
  .date {
    font-size: 0.12rem;
    font-weight: 400;
    text-align: center;
    color: #bcbcbc;
    line-height: 12px;
    margin-top: 0.24rem;
  }
  .question {
    margin: 0.24rem 0.16rem 0 0;
    overflow: hidden;
    clear: both;
    .head-img {
      float: right;
      margin-top: 0.08rem;
    }
    .text {
      float: right;
      background: rgba(64, 152, 255, 0.15);
      border-radius: 0.02rem;
      padding: 0.12rem 0.16rem;
      margin-right: 0.12rem;
      max-width: 2.14rem;
      position: relative;
    }
  }
  .transportBillNumber {
    margin: -4px 0.5rem 0 0;
  }
  .answer {
    margin: 0.24rem 0 0 0.16rem;
    overflow: hidden;
    clear: both;
    .head-img {
      float: left;
      margin-top: 0.08rem;
    }
    .text {
      float: left;
      background: rgba(43, 173, 64, 0.15);
      border-radius: 0.02rem;
      padding: 0.12rem 0.16rem;
      margin-left: 0.12rem;
      max-width: 2.14rem;
    }
  }
}
</style>
