/**
 * @param {Number} type - 0: 业务咨询 1: 举报投诉
 */

import { getBusinessConsultationById, getReportComplaintsById } from '@api/customer';

export default type => {
  let differenceInfo;
  if (type) {
    differenceInfo = { typeText: '投诉详情', apiName: getReportComplaintsById };
  } else {
    differenceInfo = {
      typeText: '咨询详情',
      apiName: getBusinessConsultationById
    };
  }
  return differenceInfo;
};
